import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { getPageContent } from "utils/cms";
// import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
// const parse = require("html-react-parser");
gsap.registerPlugin(ScrollToPlugin);
// import React from "react";

const About = (props) => {
	// const [status, setStatus] = useState();
	const [content, setContent] = useState();
	const onOffBlock = useRef();
	const fadeInElementWork = useRef([]);
	fadeInElementWork.current = [];

	const addToRef = (el) => {
		if (el && !fadeInElementWork.current.includes(el)) {
			fadeInElementWork.current.push(el);
		}
	};

	useEffect(() => {
		getPageContent("about").then((result) => {
			// console.log(result.items[0].fields);
			setContent(result.items[0].fields);
		});
	}, []);

	const animateInitialize = () => {
		gsap.set(onOffBlock.current, {
			display: "none",
		});
	};

	const animateIn = () => {
		gsap.set(onOffBlock.current, {
			display: "block",
		});
		gsap.from(fadeInElementWork.current, {
			opacity: 0,
			y: 20,
			duration: 0.8,
			stagger: 0.1,
		});
	};

	const animateOut = () => {
		gsap.to(fadeInElementWork.current, {
			opacity: 0,
			y: 20,
			// duration: 0.3,
			stagger: 0.1,
		});
		scrollToTop();
	};

	const animateEnd = () => {};

	const scrollToTop = () => {
		gsap.to(window, {
			duration: 0.5,
			scrollTo: 0,
		});
	};

	useEffect(() => {
		if (props.transitionStatus === "entering") {
			animateInitialize();
		} else if (props.transitionStatus === "entered") {
			animateIn();
		} else if (props.transitionStatus === "exiting") {
			animateOut();
		} else if (props.transitionStatus === "exited") {
			animateEnd();
		}
		return () => {
			// clean up処理を記述する。a
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.transitionStatus]);

	const renderRichTextOptions = {
		renderMark: {
			[MARKS.BOLD]: (text) => <b>{text}</b>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
			[BLOCKS.HR]: (node, children) => <hr className="mt-8 mb-4" />,
		},
		renderText: (text) => {
			return text.split("\n").reduce((children, textSegment, index) => {
				return [...children, index > 0 && <br key={index} />, textSegment];
			}, []);
		},
	};

	return (
		<div ref={onOffBlock} className="about__container ">
			<div className="">{content && content.aboutImages.map((image, index) => <img ref={addToRef} key={index} className=" w-full mb-4" src={image.fields.file.url} alt={image.fields.title} />)}</div>
			<div ref={addToRef} className="my-10 w-5/6 sm:w-2/3 mx-auto leading-6">
				<div className=" mb-4">{content && documentToReactComponents(content.aboutText, renderRichTextOptions)}</div>
			</div>
			<div className="w-2/3 mx-auto mt-20">{content && <img ref={addToRef} className=" w-full mb-4" src={content.aboutPortrait.fields.file.url} alt={content.aboutPortrait.fields.title} />}</div>
			<div ref={addToRef} className="my-10 w-5/6 sm:w-2/3 mx-auto leading-6">
				<div className=" mb-4">{content && documentToReactComponents(content.aboutCv, renderRichTextOptions)}</div>
			</div>
		</div>
	);
};

export default About;
