import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { getPageContent } from "utils/cms";
// import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
const parse = require("html-react-parser");
gsap.registerPlugin(ScrollToPlugin);
// import React from "react";

const Work = (props) => {
	// const [status, setStatus] = useState();
	const [content, setContent] = useState();
	const [prevContent, setPrevContent] = useState();
	const [nextContent, setNextContent] = useState();
	const onOffBlock = useRef();
	const fadeInElementWork = useRef([]);
	fadeInElementWork.current = [];
	let params = useParams();

	const addToRef = (el) => {
		if (el && !fadeInElementWork.current.includes(el)) {
			fadeInElementWork.current.push(el);
		}
	};

	useEffect(() => {
		// 個別の記事のみを取得する場合の関数 getPageContentWithSlug
		// getPageContentWithSlug("work", params.workId).then((result) => {
		// 	setContent(result.items[0].fields);
		// 	checkNextWorks(params.workId);
		// });
		getWorkContent(params.workId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getWorkContent = (slug) => {
		getPageContent("home").then((result) => {
			const resultItem = result.items[0];
			// setContent(result.fields);
			const workIndex = resultItem.fields.homeWorks.findIndex((v) => v.fields.workSlug === slug);
			const indexLength = resultItem.fields.homeWorks.length;
			setContent(resultItem.fields.homeWorks[workIndex].fields);

			// 前後のコンテンツを取得
			if (workIndex > 0) {
				setNextContent(resultItem.fields.homeWorks[workIndex - 1].fields);
			}
			if (workIndex < indexLength - 1) {
				setPrevContent(resultItem.fields.homeWorks[workIndex + 1].fields);
			}
		});
	};

	const animateInitialize = () => {
		gsap.set(onOffBlock.current, {
			display: "none",
		});
		// setStatus("entering");
		// console.log("work animete ininialized");
	};

	const animateIn = () => {
		gsap.set(onOffBlock.current, {
			display: "block",
		});
		gsap.from(fadeInElementWork.current, {
			opacity: 0,
			y: 20,
			duration: 0.8,
			stagger: 0.1,
		});
		// setStatus("entered");
		// console.log("work animete in");
	};

	const animateOut = () => {
		gsap.to(fadeInElementWork.current, {
			opacity: 0,
			y: 20,
			// duration: 0.3,
			stagger: 0.1,
		});
		scrollToTop();
		// setStatus("exiting");
		// console.log("work animete out");
	};

	const animateEnd = () => {
		// setStatus("exited");
		// console.log("work animete End");
	};

	const scrollToTop = () => {
		gsap.to(window, {
			duration: 0.5,
			scrollTo: 0,
		});
	};

	useEffect(() => {
		if (props.transitionStatus === "entering") {
			animateInitialize();
		} else if (props.transitionStatus === "entered") {
			animateIn();
		} else if (props.transitionStatus === "exiting") {
			animateOut();
		} else if (props.transitionStatus === "exited") {
			animateEnd();
		}
		return () => {
			// clean up処理を記述する。
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.transitionStatus]);

	const website_url = "https://stackoverflow.com";
	const renderRichTextOptions = {
		renderMark: {
			[MARKS.BOLD]: (text) => <b>{text}</b>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
			[BLOCKS.HR]: (node, children) => <hr className="mt-8 mb-4" />,
			[INLINES.HYPERLINK]: (node, children) => (
				<a className=" text-gray-400 hover:text-gray-800" href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? "_self" : "_blank"}`}>
					{children}
				</a>
			),
			// [BLOCKS.EMBEDDED_ASSET]: (node) => {<>{node}</>},
		},
		renderText: (text) => {
			return text.split("\n").reduce((children, textSegment, index) => {
				return [...children, index > 0 && <br key={index} />, textSegment];
			}, []);
		},
	};

	const addLineBrake = (baseText) => {
		return baseText.split("\n").join("<br>");
	};

	return (
		<div ref={onOffBlock} className="work__container ">
			<div ref={addToRef} className="mb-6">
				<h2 className=" text-center text-base sm:text-xl">{content && content.workTitle}</h2>
			</div>
			<div className="">{content && content.workImages.map((image, index) => <img ref={addToRef} key={index} className=" w-full mb-4" src={`${image.fields.file.url}?fm=webp&w=1600`} alt="" />)}</div>
			<div ref={addToRef} className="my-10 w-5/6 sm:w-2/3 mx-auto leading-6">
				<div className=" mb-4">{content && documentToReactComponents(content.workDescription, renderRichTextOptions)}</div>
			</div>
			<div ref={addToRef} className="w-2/3 mb-16 mx-auto">
				<div className=" text-gray-500 text-center text-xs leading-5 border-t pt-6">{content && parse(addLineBrake(content.workCredits))}</div>
			</div>
			<div ref={addToRef} className=" w-9/10 sm:w-2/3 mx-auto flex justify-between text-xxs sm:text-xs">
				<div className=" w-auto text-left duration-300 text-gray-400 hover:text-gray-800 hover:-translate-x-2">
					{nextContent && <Link to={`../work/${nextContent.workSlug}`}>← {nextContent.workTitle}</Link>}
				</div>
				<div className=" w-auto text-right duration-300 text-gray-400 hover:text-gray-800 hover:translate-x-2">
					{prevContent && <Link to={`../work/${prevContent.workSlug}`}>{prevContent.workTitle} →</Link>}
				</div>
			</div>
		</div>
	);
};

export default Work;
