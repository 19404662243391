import React, { useState } from "react";
import { Link } from "react-router-dom";
// import ContentLoader from "react-content-loader";
import placeholderImg from "assets/WorkCard/placeholder_gray.svg";

// const MyLoader = () => (
// 	<ContentLoader width="100%" height="100%">
// 		<rect width="600" height="400" />
// 	</ContentLoader>
// );

const WorkCard = React.forwardRef((props, ref) => {
	const [imgLoaded, setImgLoaded] = useState(false);

	const imageLoaded = () => {
		setImgLoaded(true);
	};

	return (
		<Link to={`work/${props.content.workSlug}`}>
			<div ref={ ref } className="home__fadein">
				<div className="transition duration-100 hover:opacity-50">
					{/* <div className="home__fadein hover:opacity-50 ease-in-out duration-300"> */}
					<div className=" rounded overflow-hidden">
						<img
							className={` w-full duration-1000 ${imgLoaded ? "opacity-100" : " h-0 opacity-0"}`}
							src={`${props.content.workImages[0].fields.file.url}?fm=webp&w=400`}
							alt=""
							onLoad={() => {
								imageLoaded();
							}}
						/>
						<div className={` ${imgLoaded ? "hidden" : "block"}`}>
							{/* <MyLoader /> */}
							<div className=" animate-pulse">
								<img className="w-full" src={placeholderImg} alt="" />
							</div>
						</div>
						{/* <img src="https://placehold.co/600x400" alt="" /> */}
					</div>
					<h4 className=" text-xs mt-1">{props.content.workTitle}</h4>
				</div>
			</div>
		</Link>
	);
});

export default WorkCard;
