import { Link } from "react-router-dom";

const Navigation = (props) => {
	// constructor (props) {
	//   super(props);
	//   // this.state = { authors: [] };

	// }

	return (
		<header className="App-header">
			<div className="flex flex-row justify-between  w-full mx-auto py-12">
				<div className="flex flex-row">
					<div className=" -mt-2 text-2xl sm:-mt-1 sm:text-3xl">
						<Link to="/">atrium</Link>
					</div>
				</div>
				<ul className="text-gray-400 flex flex-row justify-end">
					<li className=" text-xs ml-4 sm:text-base sm:ml-8 duration-200 hover:text-gray-800">
						<Link to="/">works</Link>
					</li>
					<li className=" text-xs  ml-4 sm:text-base sm:ml-8 duration-200 hover:text-gray-800 ">
						<Link to="/about">about</Link>
					</li>
					<li className=" text-xs ml-4 sm:text-base sm:ml-8 duration-200 hover:text-gray-800 ">
						<Link to="/contact">contact</Link>
					</li>
				</ul>
			</div>
		</header>
	);
};

export default Navigation;
