import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { getPageContent } from "utils/cms";
// import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
// import { render } from "@testing-library/react";
gsap.registerPlugin(ScrollToPlugin);
// import React from "react";

const Contact = (props) => {
	const [content, setContent] = useState();
	const [formName, setFormName] = useState("");
	const [formEmail, setFormEmail] = useState("");
	const [formMessage, setFormMessage] = useState("");
	const [isFormSent, setIsFormSent] = useState(false);
	const [isFormSending, setIsFormSending] = useState(false);
	const onOffBlock = useRef();
	const fadeInElementWork = useRef([]);
	fadeInElementWork.current = [];

	const addToRef = (el) => {
		if (el && !fadeInElementWork.current.includes(el)) {
			fadeInElementWork.current.push(el);
		}
	};

	useEffect(() => {
		getPageContent("contact").then((result) => {
			// console.log(result.items[0].fields);
			setContent(result.items[0].fields);
		});
	}, []);

	const animateInitialize = () => {
		gsap.set(onOffBlock.current, {
			display: "none",
		});
	};

	const animateIn = () => {
		gsap.set(onOffBlock.current, {
			display: "block",
		});
		gsap.from(fadeInElementWork.current, {
			opacity: 0,
			y: 20,
			duration: 0.8,
			stagger: 0.1,
		});
	};

	const animateOut = () => {
		gsap.to(fadeInElementWork.current, {
			opacity: 0,
			y: 20,
			// duration: 0.3,
			stagger: 0.1,
		});
		scrollToTop();
	};

	// const formAnimateOut = () => {
	// 	gsap
	// 		.to(".contactForm", {
	// 			opacity: 0,
	// 			y: 20,
	// 			// duration: 0.3,
	// 			stagger: 0.1,
	// 		})
	// 		.then(setIsFormSent(true));
	// 	// scrollToTop();
	// };

	const animateEnd = () => {};

	const scrollToTop = () => {
		gsap.to(window, {
			duration: 0.5,
			scrollTo: 0,
		});
	};

	useEffect(() => {
		if (props.transitionStatus === "entering") {
			animateInitialize();
		} else if (props.transitionStatus === "entered") {
			animateIn();
		} else if (props.transitionStatus === "exiting") {
			animateOut();
		} else if (props.transitionStatus === "exited") {
			animateEnd();
		}
		return () => {
			// clean up処理を記述する。
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.transitionStatus]);

	const renderRichTextOptions = {
		renderMark: {
			[MARKS.BOLD]: (text) => <b>{text}</b>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
			[BLOCKS.HR]: () => <hr className="mt-8 mb-4" />,
		},
		renderText: (text) => {
			return text.split("\n").reduce((children, textSegment, index) => {
				return [...children, index > 0 && <br key={index} />, textSegment];
			}, []);
		},
	};

	const encode = (data) => {
		return Object.keys(data)
			.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	};

	const handleSubmit = (event) => {
		const sendData = {
			name: formName,
			email: formEmail,
			message: formMessage,
		};
		// const sendData = [ formName, formEmail, formMessage ];

		fetch("https://atrium-design.netlify.app/.netlify/functions/sendmail", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			// body: { name: formName, email: formEmail, message: formMessage },
			body: encode(sendData),
		})
			.then((res) => {
				// console.log(res);
				// console.log("dsafdsafdsafdsa");
				// formAnimateOut();
				setIsFormSent(true);
			})
			.catch((error) => alert(error));

		// console.log(JSON.stringify(sendData));
		// console.log("form sent");
		setIsFormSending(true);
		event.preventDefault();
	};

	return (
		<div ref={onOffBlock} className="about__container ">
			<div ref={addToRef} className=" w-7/8 sm:w-2/3">
				<div className=" mb-4 leading-6">{content && documentToReactComponents(content.contactText, renderRichTextOptions)}</div>
			</div>
			<div ref={addToRef} className=" contactForm w-7/8 sm:w-full md:w-3/4 mt-10">
				{isFormSent ? (
					<div className="sm:w-5/6 text-slate-500 border-t pt-6">
						<p className="mb-2">お問合せを、ありがとうございました。近日中にご連絡をさせていただきます。</p>
						<p className="text-xs">※ もし数日経っても返信がなかった場合、送信エラーの可能性もありますので、大変申し訳ありませんが、その際は再度お問合せいただけますよう、よろしくお願い致します。</p>
					</div>
				) : (
					<div className="">
						<form onSubmit={handleSubmit}>
							<div className=" mb-5">
								<label className=" block text-xs font-bold mb-2" htmlFor="name">
									お名前
								</label>
								<input className=" w-full sm:w-2/3 border p-2" id="name" type="text" required placeholder="お名前を入力してください" value={formName} onChange={(e) => setFormName(e.target.value)} />
							</div>
							<div className=" mb-5">
								<label className=" block text-xs font-bold mb-2" htmlFor="email">
									eメールアドレス
								</label>
								<input
									className=" w-full sm:w-2/3 border p-2"
									id="email"
									type="text"
									required
									placeholder="eメールアドレスを入力してください"
									value={formEmail}
									onChange={(e) => setFormEmail(e.target.value)}
								/>
							</div>
							<div className=" mb-5">
								<label className=" block text-xs font-bold mb-2" htmlFor="message">
									お問合せ内容
								</label>
								<textarea
									className=" w-full sm:w-2/3 border p-2"
									id="message"
									rows="14"
									required
									placeholder="お問い合わせ内容を入力してください"
									value={formMessage}
									onChange={(e) => setFormMessage(e.target.value)}
								/>
							</div>
							<div className="">
								<button className=" relative w-24 p-2 text-xs tracking-wide font-bold text-white bg-slate-400 hover:bg-gray-800 duration-400 transition" type="submit">
									送信する
								</button>
							</div>
						</form>
						{isFormSending && (
							<div className=" absolute top-0 left-0 w-full sm:w-2/3 h-full bg-slate-200 opacity-50 flex justify-center items-center">
								<div className="animate-spin h-10 w-10 border-4 border-gray-600 rounded-full border-t-transparent"></div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Contact;
