const Footer = (props) => {

	return (
		<div className=" mt-20 mb-8">
			<p className="text-xxs text-gray-300">©2022 デザイン事務所atrium. All Rights Reserved.</p>
		</div>
	);
};

export default Footer;
