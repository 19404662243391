import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import WorkCard from "components/WorkCard";
import { getPageContent } from "utils/cms";
gsap.registerPlugin(ScrollToPlugin);

const Home = (props) => {
	// const [status, setStatus] = useState();
	const [content, setContent] = useState();
	const onOffBlock = useRef();
	const fadeInElements = useRef([]);
	fadeInElements.current = [];

	useEffect(() => {
		getPageContent("home").then((result) => {
			setContent(result.items[0].fields);
		});
		// console.log("contents fetched");
	}, []);

	const addToRef = (el) => {
		if (el && !fadeInElements.current.includes(el)) {
			fadeInElements.current.push(el);
			// console.log(fadeInElements.current);
		}
	};

	const animateInitialize = () => {
		gsap.set(onOffBlock.current, {
			display: "none",
		});
		// setStatus("entering");
		// console.log("animete ininialized");
	};

	const animateIn = () => {
		gsap.set(onOffBlock.current, {
			display: "block",
		});
		gsap.from(fadeInElements.current, {
			opacity: 0,
			y: 20,
			duration: 0.5,
			stagger: 0.05,
		});
		// setStatus("entered");
		// console.log("animete in");
	};

	const animateOut = () => {
		gsap.to(fadeInElements.current, {
			opacity: 0,
			y: 20,
			// duration: 0.3,
			stagger: 0.05,
		});
		scrollToTop();
		// setStatus("exiting");
		// console.log("animete out");
	};

	const animateEnd = () => {
		// setStatus("exited");
		// console.log("animete End");
	};

	const scrollToTop = () => {
		gsap.to(window, {
			duration: 0.5,
			scrollTo: 0,
		});
	};

	useEffect(() => {
		if (props.transitionStatus === "entering") {
			animateInitialize();
		} else if (props.transitionStatus === "entered") {
			animateIn();
		} else if (props.transitionStatus === "exiting") {
			animateOut();
		} else if (props.transitionStatus === "exited") {
			animateEnd();
		}
		return () => {
			// clean up処理を記述する。
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.transitionStatus]);

	return (
		<div ref={onOffBlock} className="home__container">
			<div ref={addToRef} className="w-full mx-auto mt-0 mb-12 sm:mt-4">
				<p className=" w-full text-xs leading-5 sm:leading-6 sm:w-2/3 sm:text-base">{content && content.homeDescription}</p>
			</div>
			<div className="w-full mx-auto">
				<div className="grid grid-cols-1 sm:grid-cols-3 gap-4 gap-y-8">{content && content.homeWorks.map((work, index) => <WorkCard ref={addToRef} key={index} content={work.fields} />)}</div>
			</div>
		</div>
	);
};

export default Home;
