import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";
import Home from "routes/Home";
import Work from "routes/Work";
import About from "routes/About";
import Contact from "routes/Contact";
import Navigation from "components/Navigation";
import Footer from "components/Footer";
// import { stringify } from "postcss";

const routes = [
	{ path: "/", Component: Home, name: "Home" },
	{ path: "/work/:workId", Component: Work, name: "Work" },
	{ path: "/about", Component: About, name: "About" },
	{ path: "/contact", Component: Contact, name: "Contact" },
];

const MainRouter = (props) => {
	// const [transitionOn, setTransitionOn] = useState(false);
	// const [transitionStatus, setTransitionStatus] = useState();

	let location = useLocation();
	// console.log(location);

	useEffect(() => {
		// console.log("Location changed");
	}, [location]);

	useEffect(() => {
		// console.log("レンダーされました。");
		// setTransitionOn(true);
		return () => {
			// clean up処理を記述する。
			// console.log("クリーンアップします。");
		};
	});

	useEffect(() => {
		// console.log("初回Routerレンダーされました。");
		// setTransitionOn(true);
		return () => {
			// clean up処理を記述する。
			// console.log("初回ROuterクリーンアップします。");
		};
	}, []);

	// useEffect(() => {
	// 	// console.log("transitionOnがRouter変更レンダーされました。");
	// 	return () => {
	// 		// clean up処理を記述する。
	// 		// console.log("変更Routerクリーンアップします。");
	// 	};
	// }, [transitionStatus]);

	const duration = 800;

	return (
		<div className="App w-11/12 max-w-6xl mx-auto font-sans text-base text-gray-700">
			<TransitionGroup component={null}>
				<Navigation transitionStatus={"test"} />
				<Transition key={location.key} timeout={duration}>
					{(status) => (
						<>
							<Routes location={location}>
								{routes.map((route, index) => (
									<Route path={route.path} key={index} element={<route.Component transitionStatus={status} />} />
								))}
								<Route
									path="*"
									element={
										<main style={{ padding: "1rem" }}>
											<p>404 not found.</p>
										</main>
									}
								/>
							</Routes>
						</>
					)}
				</Transition>
				<Footer />
			</TransitionGroup>
		</div>
	);
};

export default MainRouter;
