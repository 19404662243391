const contentful = require("contentful");

const client = contentful.createClient({
	// This is the space ID. A space is like a project folder in Contentful terms
	space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
	// This is the access token for this space. Normally you get both ID and the token in the Contentful web app
	accessToken: process.env.REACT_APP_CONTENTFUL_API_TOKEN,
});

export const getPageContent02 = (pageID) => {
	// const client = contentful.createClient({
	// 	// This is the space ID. A space is like a project folder in Contentful terms
	// 	space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
	// 	// This is the access token for this space. Normally you get both ID and the token in the Contentful web app
	// 	accessToken: process.env.REACT_APP_CONTENTFUL_API_TOKEN,
	// });
	// // This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token.
	client
		.getEntries({ content_type: "home" })
		.then((entries) => {
			console.log(entries);
			return entries;
		})
		.catch((err) => console.log(err));
};

export const getPageContent = (pageID) => {
	// This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token.
	return new Promise((resolve, reject) => {
		client
			.getEntries({ content_type: pageID })
			.then(resolve)
			.catch((err) => {
				/* eslint-disable-next-line */
				console.error(err);
				reject(err);
			});
	});
};

export const getPageContentWithSlug = (pageID, slug) => {
	return new Promise((resolve, reject) => {
		client
			.getEntries({ 
				content_type: pageID,
				'fields.workSlug[match]': slug, 
			})
			.then(resolve)
			.catch((err) => {
				/* eslint-disable-next-line */
				console.error(err);
				reject(err);
			});
	});
};

// export const mapStateToContent = (pageId) => (state) => ({
// 	content: getPageContent(pageId)(state.content, state.language),
// 	englishContent: getPageContent(pageId)(state.content, "en"),
// 	japaneseContent: getPageContent(pageId)(state.content, "jp"),
// 	language: state.language,
// 	firstLoad: state.loader.firstLoad,
// });
